<template>

  <div class="prepare-selfie fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-row justify="center" class="mt-0">
          <v-col cols="12" class="mt-0 py-0">
            <div class="my-0 pt-0 black--text">
              <h1 class="text-center today-regular text-title">AUTENTICAR USUARIO</h1>
            </div>
          </v-col>
          <v-col cols="11" md="8">
            <div class="mt-6">
              <h3 class="text-justify mb-0 today-regular line-height-1">Para continuar con el proceso de crédito ingresa la clave dinámica.
                Consúltala en la pantalla principal de la app de <span class="primary--text">BIOCREDIT</span> donde
                esta inscrito el servicio. Una vez ingreses el código de validación
                iniciarás el proceso de solicitud de crédito.</h3>
            </div>
          </v-col>
          <v-col class="mt-6" cols="12" md="6" lg="5">

            <v-row>
              <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                <v-text-field
                    type="text"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-${i}`"
                    v-model="key.value"
                    v-on:keyup="handleActivationInput($event)"
                    class="px-0 input-field code-input"
                    outlined
                    dense
                    color="primary"
                    maxLength="1"></v-text-field>
              </v-col>

            </v-row>

            <v-layout justify-center pt-12>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    @click="goPrepareSelfie"
                    color="primary"
                    class="px-12 text-capitalize vardi-button"
                    elevation="0"
                    large>
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>

</template>

<script>

import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";

export default {
  name: 'AuthUser',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      loading: false,
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
    }
  },
  mounted() {
  },
  methods: {
    goPrepareSelfie() {
      this.$router.push('/simulador');
    },
    handleActivationInput: function (event) {

      event.target.select();
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      console.log(this.activationKeyFields, this.activationKeyFields[index].value.length, value, index, maxlength, this.$refs);

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        console.log('salta 2');
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          console.log('undefined salta 2');
          event.preventDefault();
          return;
        }

        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {
          console.log('exception: ', e);
        }

        event.preventDefault();
      }

      if (event.key == 'Backspace') {
        if (typeof this.activationKeyFields[index - 1] == 'undefined') {
          console.log('Backspace salta 2');
          event.preventDefault();
          return;
        }

        try {
          this.$refs[`input-${parseInt(index - 1)}`][0].focus();
        } catch (e) {
          console.log('exception: ', e);
        }

        event.preventDefault();
      }

      console.log('handleActivationInput: ', event, value, this.activationKeyFields);

    },
  }
}

</script>
